.App{
  background-color: #f2f5f7;
  /* font-family: 'F37gingerpro,sans-serif' !important; */
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: #000066;
}

.p-card-content{
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 20px;
}

.p-card-title{
  color: #000066;
}
