.about-content {
    margin-bottom: 125px;
}

.responsive-image {
    position: relative;
    max-width: 100%;
}
  
.responsive-image__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.p-card-content li{
    list-style-type: square;
}