.countryFlags {
    height: 36px;
    padding: 3px 7px 7px 3px;
}

.p-menubar{
    border: none !important;
}

.p-menuitem:hover{
    border-bottom: 4px solid #fecc00;
}
.p-menuitem-text{
    color: #000066 !important;
    font-weight: bolder;
}

.p-menuitem-icon{
    color: #000066 !important;
    font-weight: bolder;
}